
html, body, .container, .container-fluid, .content {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.content, .logs {
  display: flex;
  flex-direction: column;
}

.logs, .console {
  flex-grow: 1;
  flex-basis: 0;
  max-height: 100%;
}

.page-header {
  margin-top: 20px;
}

.refresh-label {
  margin-left: 8px;
}

.refresh-radio {
  margin-left: 8px;
}

.settings {
  display: flex;
}

.setting:not(:first-child) {
  margin-left: 30px;
}

.options {
  display: flex;
  justify-content: space-between;
}

.option {
  font-size: 12px;
  text-transform: uppercase;
}

.option.disabled {
  color: black;
  text-decoration: none;
  cursor: default;
}

.has-rcon {
  .console {
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
  }

  .command {
    display: block;
  }
}

.command {
  display: none;
  margin-bottom: 15px;
  border-radius: 0 0 2px 2px;
  width: 100%;
  border: 1px solid #999;
  border-top: 0;
  padding: 5px 8px 3px;
  background: #EEE;
  outline: none;
  font-size: 12px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.console {
  position: relative;
  font-size: 13px;
  margin-bottom: 15px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 4px;
  overflow: hidden;
  background: black;
  padding: 8px 6px;
  color: #ccc;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  white-space: pre-wrap;

  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;

  &.has-data:not(.is-loading) {
    overflow: scroll;
  }

  &.has-more:not(.is-loading) {
    &[data-filter="last"]::before, &[data-filter="first"]::after {
      display: block;
      margin: auto;
      text-align: center;
      font-size: 13px;
      color: #A9D1E0;
    }

    &[data-filter="last"]::before {
      content: '▲ scroll up to load more ▲';
      margin: 12px 50px 20px; /* 12 = 20 - console.padding */
    }

    &[data-filter="first"]::after {
      content: '▼ scroll down load more ▼';
      margin: 20px 50px 12px; /* 12 = 20 - console.padding */
    }
  }

  &.has-more.is-loading {
    &[data-filter="last"]::before, &[data-filter="first"]::after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      animation: rotate 1s linear infinite;
      border-radius: 50%;
      border: 3px solid rgba(256, 256, 256, .25);
      border-left-color: #BBB;
    }

    &[data-filter="last"]::before {
      margin: 11px auto 19px; /* 11 = 19 - console.padding */
    }

    &[data-filter="first"]::after {
      margin: 19px auto 11px; /* 7 = 15 - console.padding */
    }
  }
}

.key {
  &.key-enter {
    font-family: monospace;
    font-size: 70%;
    padding: 5px 3px 1px;
  }
}

.loader {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  animation: rotate 1s linear infinite;
  border-radius: 50%;
  border: 4px solid rgba(256, 256, 256, .25);
  border-left-color: #BBB;
}

@keyframes rotate {
  0% { transform:rotate(0deg); }
  100%{ transform:rotate(360deg); }
}

.exit-max {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.8;

  .exit-part {
    display: inline-block;
    vertical-align: top;
  }

  .exit-close {
    width: 20px;
  }

  .close-x {
    stroke: white;
    fill: transparent;
    stroke-linecap: round;
    stroke-width: 5;
  }
}

.maximized {
  padding: 0;
  margin: 0;

  .container, .container-fluid {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .selection {
    display: none;
  }

  .console {
    margin: 0;
    border-radius: 0;
  }

  .command {
    margin: 0;
    border-radius: 0;
    border: none;
  }

  .exit-max {
    display: block;
  }
}

.console.colorized {
  .startup {
    background: #006900;
  }

  .color {
    font-style: normal;
    font-weight: normal;
    text-decoration: none;

    &.color-30 { color: #eeeeee; }
    &.color-31 { color: #aa0000; }
    &.color-32 { color: #00aa00; }
    &.color-33 { color: #ffaa00; }
    &.color-34 { color: #6666aa; }
    &.color-35 { color: #aa00aa; }
    &.color-36 { color: #00aaaa; }
    &.color-37 { color: #cccccc; }
  }

  .style {
    &.style-3 { font-style: italic; }
    &.style-21 { font-weight: bold; }
    &.style-4 { text-decoration: underline; }
  }

  .action {
    &.action-command {
      color: #E9E9E9;
    }
  }

  .thread {

    &.server-thread-INFO { color: #9999ff; }
    &.scheduler-thread-INFO { color: #AD74B7; }
    &.chat-thread-INFO { color: #76A0A2; }

    &.main-thread-INFO { color: #ACB589; }
    &.watchdog-thread-INFO { color: #ACB589; }
    &.pool-thread-INFO { color: #ACB589; }

    &.server-thread-WARN,
    &.scheduler-thread-WARN,
    &.chat-thread-WARN,
    &.main-thread-WARN,
    &.watchdog-thread-WARN,
    &.pool-thread-WARN {
      color: #ffff55;
    }

    &.server-thread-ERROR,
    &.scheduler-thread-ERROR,
    &.chat-thread-ERROR,
    &.main-thread-ERROR,
    &.watchdog-thread-ERROR,
    &.pool-thread-ERROR {
      color: #ff5555;
    }
  }
}
